<template lang='pug'>
	.d-flex.flex-column.mx-auto.subscriber
		h3.text-bold.mb-4(:class="{'b1': isMobile}") {{ $t('h1.subscribersControl') }}

		.card-basic.mb-3
			h3.text-bold.mb-2(:class="{'b1': isMobile}") {{ $t('pages.whereAmI')}}
			span.b2.text-grey {{ $t('pages.whereAmIAnswer') }}

		.card-basic.mb-3
			h3.text-bold.mb-2(:class="{'b1': isMobile}") {{ $t('pages.paymentWithoutMyAgree')}}
			span.b2.text-grey {{ $t('pages.paymentWithoutMyAgreeAnswer') }}

		.card-basic.mb-3
			h3.text-bold.mb-2(:class="{'b1': isMobile}") {{ $t('pages.paymentWithoutSMS')}}
			p.b2.text-grey.mb-3 {{ $t('pages.paymentWithoutSMSAnswer') }}
			p.b2.text-grey.mb-0 {{ $t('pages.paymentWithoutSMSAnswer2') }}

		.subscriber__note.mb-4
			p.b3.mb-0 {{ $t('pages.needTelegram2') }}
			p.b3.mb-0 {{ $t('pages.canDownloadTelegram') }}&nbsp;
				a.text-primary(href='//telegram.org' target='_blank') www.telegram.org

		h3.text-bold.mb-3 {{ $t('pages.whatDoYouWantToDo') }}

		pw-btn.mb-3(:text="$t('pages.findSubscriptionWithAccess')" :variant="'grey'" @click.native="$bvModal.show('findSubscriptionWithAccess')")
		pw-btn.mb-3(:text="$t('pages.closeSubscription')" :variant="'grey'" @click.native="$bvModal.show('closeSubscription')")
		pw-btn.mb-3(:text="$t('pages.refundPayment')" :variant="'grey'" @click.native="$bvModal.show('refundPayment')")
		pw-btn(:text="$t('pages.contactSupport')" :variant="'grey'" @click.native="$bvModal.show('contactSupport')")

		modal-general(v-if="!isMobile" :id="'findSubscriptionWithAccess'" :title="$t('pages.findSubscriptionWithAccess')")
			.d-flex.flex-column.b1.mb-4.position-relative
				.subscriber__item {{ $t('bot.goTo') }}
				.subscriber__item(v-html="$t('bot.pressStart')")
				.subscriber__item.mb-0(v-html="$t('bot.pressMenu')")
				svg.subscriber__dashed(xmlns="http://www.w3.org/2000/svg" width="2" height="96" viewBox="0 0 2 86" fill="none")
					path(opacity="0.3" d="M1 0.53125L1 89.5313" stroke="#52A7F9" stroke-linecap="round" stroke-dasharray="10 4")
			p.b3.text-grey.mb-4 {{ $t('bot.willFind') }}
			a.text-decoration-none(:href='botPay' target='_blank')
				.btn.btn-primary {{ $t('project.openBot') }}

		b-modal(v-if="isMobile" id="findSubscriptionWithAccess" content-class="modal-indents" header-class="p-0 border-0 align-items-center mb-4" body-class="p-0 d-flex flex-column" hide-footer hide-header-close)
			template(#modal-header="{ close }")
				h3.h3(v-html="$t('pages.findSubscriptionWithAccess')")
				icon-close(@click="close()")
			.d-flex.flex-column.b1.mb-4.position-relative
				.subscriber__item {{ $t('bot.goTo') }}
				.subscriber__item(v-html="$t('bot.pressStart')")
				.subscriber__item.mb-0(v-html="$t('bot.pressMenu')")
				svg.subscriber__dashed(xmlns="http://www.w3.org/2000/svg" width="2" height="96" viewBox="0 0 2 86" fill="none")
					path(opacity="0.3" d="M1 0.53125L1 89.5313" stroke="#52A7F9" stroke-linecap="round" stroke-dasharray="10 4")
			p.b3.text-grey.mb-4 {{ $t('bot.willFind') }}
			a.text-decoration-none(:href='botPay' target='_blank')
				.btn.btn-primary {{ $t('project.openBot') }}


		modal-general(v-if="!isMobile" :id="'closeSubscription'" :title="$t('pages.closeSubscription')")
			.d-flex.flex-column.b1.mb-4.position-relative
				.subscriber__item {{ $t('bot.goTo') }}
				.subscriber__item(v-html="$t('bot.pressStart')")
				.subscriber__item(v-html="$t('bot.pressMenu2')")
				.subscriber__item {{ $t('bot.chouseSubscription') }}
				.subscriber__item.mb-0 {{ $t('bot.cancelSubscription') }}
				svg.subscriber__dashed(v-if="!isMobile" xmlns="http://www.w3.org/2000/svg" width="2" height="171" viewBox="0 0 2 171" fill="none")
					path(opacity="0.3" d="M1 1L1 175" stroke="#52A7F9" stroke-linecap="round" stroke-dasharray="10 4")
				svg.subscriber__dashed(v-if="isMobile" xmlns="http://www.w3.org/2000/svg" width="2" height="200" viewBox="0 0 2 171" fill="none")
					path(opacity="0.3" d="M1 1L1 175" stroke="#52A7F9" stroke-linecap="round" stroke-dasharray="10 4")
			p.b3.text-grey.mb-4 {{ $t('bot.cancelDesc') }}
			a.text-decoration-none(:href='botPay' target='_blank')
				.btn.btn-primary {{ $t('project.openBot') }}

		b-modal(v-if="isMobile" id="closeSubscription" content-class="modal-indents" header-class="p-0 border-0 align-items-center mb-4" body-class="p-0 d-flex flex-column" hide-footer hide-header-close)
			template(#modal-header="{ close }")
				h3.h3(v-html="$t('pages.closeSubscription')")
				icon-close(@click="close()")
			.d-flex.flex-column.b1.mb-4.position-relative
				.subscriber__item {{ $t('bot.goTo') }}
				.subscriber__item(v-html="$t('bot.pressStart')")
				.subscriber__item(v-html="$t('bot.pressMenu2')")
				.subscriber__item {{ $t('bot.chouseSubscription') }}
				.subscriber__item.mb-0 {{ $t('bot.cancelSubscription') }}
				svg.subscriber__dashed(v-if="!isMobile" xmlns="http://www.w3.org/2000/svg" width="2" height="171" viewBox="0 0 2 171" fill="none")
					path(opacity="0.3" d="M1 1L1 175" stroke="#52A7F9" stroke-linecap="round" stroke-dasharray="10 4")
				svg.subscriber__dashed(v-if="isMobile" xmlns="http://www.w3.org/2000/svg" width="2" height="200" viewBox="0 0 2 171" fill="none")
					path(opacity="0.3" d="M1 1L1 175" stroke="#52A7F9" stroke-linecap="round" stroke-dasharray="10 4")
			p.b3.text-grey.mb-4 {{ $t('bot.cancelDesc') }}
			a.text-decoration-none(:href='botPay' target='_blank')
				.btn.btn-primary {{ $t('project.openBot') }}

		modal-general(v-if="!isMobile" :id="'refundPayment'" :title="$t('pages.refundPayment')")
			p.b3.mb-4 {{ $t('finance.concactSupport') }}
			.d-flex.flex-column.w-100.subscriber__wrap
				a.d-block.mb-2.w-100.text-decoration-none(:href="`https://t.me/${$t('contacts.support_tg')}`" target="_blank")
					pw-btn(:text="$t('h1.telegram')" :icon="'icon-telegram'")
				pw-btn-mail.w-100

		b-modal(v-if="isMobile" id="refundPayment" content-class="modal-indents" header-class="p-0 border-0 align-items-center mb-4" body-class="p-0 d-flex flex-column" hide-footer hide-header-close)
			template(#modal-header="{ close }")
				h3.h3(v-html="$t('pages.refundPayment')")
				icon-close(@click="close()")
			p.b3.mb-4 {{ $t('finance.concactSupport') }}
			.d-flex.flex-column.w-100.subscriber__wrap
				a.d-block.mb-2.w-100.text-decoration-none(:href="`https://t.me/${$t('contacts.support_tg')}`" target="_blank")
					pw-btn(:text="$t('h1.telegram')" :icon="'icon-telegram'")
				pw-btn-mail.w-100

		modal-general(v-if="!isMobile" :id="'contactSupport'" :title="$t('pages.contactSupport')" :contentClass="'max-content'")
			.d-flex.flex-column.w-100.subscriber__wrap
				a.d-block.mb-2.w-100.text-decoration-none(:href="`https://t.me/${$t('contacts.support_tg')}`" target="_blank")
					pw-btn(:text="$t('h1.telegram')" :icon="'icon-telegram'")
				pw-btn-mail.w-100

		b-modal(v-if="isMobile" id="contactSupport" content-class="modal-indents" header-class="p-0 border-0 align-items-center mb-4" body-class="p-0 d-flex flex-column" hide-footer hide-header-close)
			template(#modal-header="{ close }")
				h3.h3(v-html="$t('pages.contactSupport')")
				icon-close(@click="close()")
			.d-flex.flex-column.w-100.subscriber__wrap
				a.d-block.mb-2.w-100.text-decoration-none(:href="`https://t.me/${$t('contacts.support_tg')}`" target="_blank")
					pw-btn(:text="$t('h1.telegram')" :icon="'icon-telegram'")
				pw-btn-mail.w-100

		modal-cookie
</template>

<script>
import { bus } from '@/main';
import ModalCookie from '@/components/Modals/Cookie';
import PwBtn from '@/components/Buttons/PwBtn';
import PwBtnMail from '@/components/Buttons/PwBtnMail';
import ModalGeneral from '@/components/Modals/ModalGeneral';
import IconClose from "@/components/Common/IconClose";

export default {
	name: 'Subscriber',
	metaInfo () { return {title: bus.$t('h1.forSubcsribers')}},
	components: {
		ModalCookie,
		PwBtn,
		PwBtnMail,
		ModalGeneral,
		IconClose
	},
	data: () => ({
		isDarkTheme: null
	}),
	beforeMount() {
		this.isDarkTheme = localStorage.getItem('themeColorPaymentPage').indexOf('dark') ? true : false;
		import("@/assets/css/sass/themes/piaf." + (!this.isDarkTheme ? "dark" : "light") + ".figma.scss")
	},
	created () {
		if( !this.$route.hash ) {
			window.scrollTo(0,0);
			return;
		}
		setTimeout(() => location.href = this.$route.hash, 400)
	}
}
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.subscriber {
	width: 100%;
	max-width: 660px;
	padding-bottom: 20px;

	@include respond-below(sm) {
		padding: 13px 0 20px;
	}

	&__note {
		padding: 20px;
		background-color: var(--brand-bg-primary);
		border-radius: var(--border-radius-rounded);
		margin-bottom: 20px;

		@include respond-below(sm) {
			padding: 15px 17px;
		}
	}

	&__item {
		position: relative;
		padding-left: 29px;
		margin-bottom: 18px;

		&::before {
			content: '';
			position: absolute;
			top: 50%;
			left: 0;
			width: 14px;
			height: 14px;
			min-width: 14px;
			min-height: 14px;
			border-radius: 50%;
			background-color: var(--brand-primary);
			transform: translateY(-50%);
		}
	}

	&__dashed {
		position: absolute;
		top: 8px;
		left: 6px;
	}

	&__wrap {
		max-width: 302px;
		min-width: 302px;

		@include respond-below(sm) {
			max-width: 100%;
			min-width: 100%;
		}
	}
}
</style>
